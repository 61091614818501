import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Section from "../components/layout/section";

import * as styles from "./policy.module.scss";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { SEOv2 } from "../components/seov2";

const CookiePolicyPage = ({ data }) => (
  <Layout>
    <Section type="white">
      <div>
        <ReactMarkdown className={styles.markdown}>
          {
            data.allStrapiCookiePolicy.edges[0].node.cookiePolicy.data
              .cookiePolicy
          }
        </ReactMarkdown>
      </div>
    </Section>
  </Layout>
);

export const query = graphql`
  query {
    allStrapiCookiePolicy {
      edges {
        node {
          cookiePolicy {
            data {
              cookiePolicy
            }
          }
        }
      }
    }
  }
`;

export default CookiePolicyPage;

export const Head = () => <SEOv2 title="Политика за бисквитки" />;
